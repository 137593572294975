import React from 'react'
import { Segment, Container, Divider, Header, Image } from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

import apn_logo from '../images/logo-consulting-partner-aws-mangrove.svg'

const AboutUsPrimary = () => (
  <Segment vertical>
    <Container text textAlign="justified">
      <p>
        <FormattedMessage id="content-company-1" />
      </p>
      <p>
        <FormattedMessage id="content-company-2" />{' '}
        <a
          href="https://aws.amazon.com/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
          target="_blank"
          rel="noopener noreferrer"
        >
          Amazon Web Services
        </a>{' '}
        <FormattedMessage id="content-company-3" />
      </p>
    </Container>

    <Container text>
      <Divider />
    </Container>

    <Container text>
      <a
        href="https://aws.amazon.com/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={apn_logo} size="medium" centered alt="APN" />
      </a>
    </Container>

    <Container text>
      <Divider />
    </Container>

    <Container text textAlign="center">
      <p>
        <FormattedMessage id="content-company-4" />{' '}
        <span role="img" aria-label="France">
          🇫🇷
        </span>{' '}
        <FormattedMessage id="content-company-5" />
      </p>
    </Container>
  </Segment>
)

const AboutUs = () => (
  <Layout>
    <div className="AboutPage">
      <PageHeader>
        <Header inverted as="h1">
          <FormattedMessage id="title-company" />
        </Header>
        <p>
          <FormattedMessage id="subheader-company" />
        </p>
      </PageHeader>
      <AboutUsPrimary />
    </div>
  </Layout>
)

export default AboutUs
